import { createSlice } from "@reduxjs/toolkit";
import { GetUsersPresenceByIds } from "../../helpers/GraphHelper";
import { UserService } from '../../services/UserService';

export const acSlice = createSlice({
    name: 'ac',
    initialState: {
        userId: null,
        userCalls: [],
        acUsers: {},
        acPresence: {},
        acGroups: null,
        acContactFolders: null,
        selectedCall: null,
    },
    reducers: {
        setUserId: (state, action) => {
            state.userId = action.payload;
        },
        addUserCall: (state, action) => {
            const userCall = state.userCalls.map(function (call) { return call.chainId; }).indexOf(action.payload.chainId);

            if (userCall === -1) {
                state.userCalls.push(action.payload);
                state.selectedCall = { ...action.payload, isActive: true };
            }
        },
        updateUserCall: (state, action) => {
            const userCallIdx = state.userCalls.map(function (call) { return call.chainId; }).indexOf(action.payload.chainId);

            if (userCallIdx !== -1) {
                state.userCalls[userCallIdx] =
                {
                    ...state.userCalls[userCallIdx],
                    onHold: action.payload?.onHold ?? false,
                    holdMusic: action.payload?.holdMusic ?? false,
                    updateStart: action.payload?.updateStart,
                    transferring: action.payload?.transferring ?? false
                };
            }
        },
        removeUserCall: (state, action) => {
            state.userCalls = state.userCalls.filter(call => call.chainId !== action.payload.chainId);
        },
        setSelectedCall: (state, action) => {
            state.selectedCall = action.payload;
        },
        setAcUsers: (state, action) => {
            if (action.payload.type === "recent" || action.payload.type === "favorite" || action.payload.type === "search") {
                state.acUsers[action.payload.type] = action.payload.data;
            } else {
                //Group
                if (action.payload.type in state.acUsers && (action.payload.favChanged === undefined)) {
                    state.acUsers[action.payload.type] = { skipToken: action.payload.data.skipToken, loading: false, users: state.acUsers[action.payload.type].users.concat(action.payload.data.users) };
                } else {
                    state.acUsers[action.payload.type] = action.payload.data;
                }
            }
        },
        setAcLoadingUsers: (state, action) => {
            if (action.payload in state.acUsers) {
                state.acUsers[action.payload].loading = true;
            }
        },
        setAcSearchUsers: (state, action) => {
            if (state.acUsers["search"]) {
                state.acUsers["search"].loading = true;
                state.acUsers["search"].search = action.payload;
            }
        },
        setAcGroups: (state, action) => {
            state.acGroups = action.payload;
        },
        addAcGroup: (state, action) => {
            if (!state.acGroups) {
                state.acGroups = [action.payload];
            } else {
                state.acGroups = [...state.acGroups, action.payload];
            }
        },
        removeAcGroup: (state, action) => {
            state.acGroups = state.acGroups.filter(group => group.id !== action.payload);
            delete state.acUsers[action.payload];
        },
        setAcContactFolders: (state, action) => {
            state.acContactFolders = action.payload;
        },
        addAcContactFolder: (state, action) => {
            if (!state.acContactFolders) {
                state.acContactFolders = [action.payload];
            } else {
                state.acContactFolders = [...state.acContactFolders, action.payload];
            }
        },
        removeAcContactFolder: (state, action) => {
            state.acContactFolders = state.acContactFolders.filter(contact => contact.id !== action.payload);
            delete state.acContactFolders[action.payload];
        },
        clearAcUsersData: (state) => {
            state.acUsers = {};
            state.acPresence = {};
            state.acGroups = null;
            state.acContactFolders = null;
        },
        setAcPresence: (state, action) => {
            state.acPresence = { ...state.acPresence, ...action.payload };
        },
    }
});

export const { setUserId, addUserCall, updateUserCall, removeUserCall, ringingStart, ringingEnd, setSelectedCall,
    setAcUsers, setAcLoadingUsers, setAcSearchUsers, setAcGroups, addAcGroup, removeAcGroup,
    setAcContactFolders, addAcContactFolder, removeAcContactFolder, clearAcUsersData, setAcPresence } = acSlice.actions;

export const loadAcRecentUsers = () => async (dispatch, getState) => {
    const response = await UserService.acRecentUsers();
    if (Array.isArray(response?.data)) {
        dispatch(setAcUsers({ type: "recent", data: response.data }));
        dispatch(loadAcPresence(response.data.filter(r => !(r.id in getState().ac.acPresence)).map(r => r.id)));
    }
}

export const loadAcFavoriteUsers = () => async (dispatch, getState) => {
    const response = await UserService.acFavorites();
    if (Array.isArray(response?.data)) {
        dispatch(setAcUsers({ type: "favorite", data: response.data }));
        dispatch(loadAcPresence(response.data.filter(r => !(r.id in getState().ac.acPresence)).map(r => r.id)));
    }
}

export const loadAcGroupUsers = (groupId, loadNext, favChanged = false) => async (dispatch, getState) => {
    if (!favChanged) {
        dispatch(setAcLoadingUsers(groupId));
    }
    const skipToken = loadNext ? getState().ac.acUsers[groupId].skipToken : "";
    const response = await UserService.acGroupUsers({ id: groupId, count: 20, uri: skipToken });
    if (Array.isArray(response?.data?.users)) {
        dispatch(setAcUsers({ type: groupId, data: response.data, favChanged: favChanged }));
        dispatch(loadAcPresence(response.data.users.filter(r => !(r.id in getState().ac.acPresence)).map(r => r.id)));
    }
}

export const loadAcSearchUsers = (search, filter = "ad") => async (dispatch, getState) => {

    if (search?.length < 3) {
        dispatch(setAcUsers({ type: "search", data: { search: search, loading: false, filter: filter } }));
        return;
    }

    if (filter === "ad" || filter === "all") {
        dispatch(setAcSearchUsers(search));
        const response = await UserService.acSearchAllUsers(search);
        if (Array.isArray(response?.data)) {
            dispatch(setAcUsers({ type: "search", data: { users: response.data, search: search, loading: false, filter: filter } }));
            dispatch(loadAcPresence(response.data.filter(r => !(r.id in getState().ac.acPresence)).map(r => r.id)));
        }
    }

    if (filter === "outlook" || filter === "all") {
        const regexp = new RegExp(search, 'i');
        let data = [];

        getState().ac.acContactFolders.forEach(folder => {
            let current = getState().ac.acUsers[folder.id]?.users.filter(x => regexp.test(x.displayName));
            if (current ?? false) data = [...data, ...current]
        });

        if (filter === "all" && getState().ac.acUsers['search']?.users) {
            data = [...getState().ac.acUsers['search']?.users, ...data]
        }

        dispatch(setAcUsers({ type: "search", data: { users: data, search: search, loading: false, filter: filter } }));
    }
}

export const loadAcExternalUsers = (folderId, loadNext, favChanged = false) => async (dispatch, getState) => {
    if (!favChanged) {
        dispatch(setAcLoadingUsers(folderId));
    }

    const skipToken = loadNext ? getState().ac.acUsers[folderId].skipToken : "";
    const response = await UserService.acContactFolderUsers({ id: folderId, count: 999, uri: skipToken });
    if (Array.isArray(response?.data?.users)) {
        dispatch(setAcUsers({ type: folderId, data: response.data, favChanged: favChanged }));
    }
}

export const loadAcGroups = () => async (dispatch, getState) => {
    if (getState().ac.acGroups === null) {
        const response = await UserService.acSelectedGroups();
        if (Array.isArray(response?.data)) {
            dispatch(setAcGroups(response.data));
        }
    }
}

export const loadAcContactFolders = () => async (dispatch, getState) => {
    if (getState().ac.acContactFolders === null) {
        const response = await UserService.acContactFoldersInUse();
        if (Array.isArray(response?.data)) {
            dispatch(setAcContactFolders(response.data));
        }
    }
}

export const loadAcPresence = (ids) => async dispatch => {
    if (ids?.length > 0) {
        const presence = {};
        const response = await GetUsersPresenceByIds(ids);
        if (Array.isArray(response?.value)) {
            response.value.forEach(x => presence[x.id] = x.availability);
            dispatch(setAcPresence(presence));
        }
    }
}

export const reloadAcPresence = () => async (dispatch, getState) => {
    const ids = Object.keys(getState().ac.acPresence);
    dispatch(loadAcPresence(ids));
}

export const selectAcUsers = (state, type) => (type in state.ac.acUsers ? state.ac.acUsers[type] : null);

export default acSlice.reducer;