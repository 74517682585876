import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Divider, Card, CardContent, Typography, Button, IconButton } from '@mui/material';
import { ArrowForward, Refresh } from '@mui/icons-material';

import useTheme from '@mui/styles/useTheme';

const useStyles = makeStyles(theme => ({
    header: {
        padding: theme.spacing(1.5, 0),
    },
    body: {
        padding: theme.spacing(2),
    },
    bodyTwo: {
        padding: theme.spacing(1),
    },
    flexBody: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    noPadding: {
        padding: theme.spacing(0),
    },
    slim: {
        padding: 0,
    },
    flexNoWrap: {
        display: 'flex',
        flexWrap: 'nowrap',
    },
    justifyCenter: {
        justifyContent: 'center',
    },
    gutterBottom: {
        marginBottom: theme.spacing(1),
    },
    fill: {
        height: '100%',
        width: '100%',
        '& > :first-of-type': {
            height: '100%',
            '& > :last-child': {
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                '& > *': {
                    width: '100%',
                },
            },
        },
    },
    fillTwo: {
        height: '100%',
        '& > :first-of-type': {
            height: '100%',
            '& > :last-child': {
                height: '100%',
            },
        },
    },
    headerItems: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: useTheme().spacing(0, 2),
    },
    noTitle: {
        display: 'flex',
        float: 'right',
        marginRight: useTheme().spacing(1),
        height: 36
    },
}));

export default function GroupBox(props) {
    const { children, title = null, flex = false, flexNoWrap = false, center = false, gutter = false, fill = false, fillTwo = false, slim = false, className, actionTitle, groupboxAction, showStatReleoad = false, smallBodyPadding = false, NoPadding = false, handleStateReload, headerActions, headerSubComponents, showDivider = true, isCollapsed = false, ...other } = props;
    const classes = useStyles();

    const theme = useTheme();

    const styles = {
        main: {
            padding: 0,
            '&:last-child': {
                paddingBottom: 0,
            },
        },
        noPadding: {
            padding: theme.spacing(0),
        },
        gutterBottom: {
            marginBottom: theme.spacing(1),
        },
        fill: {
            height: '100%',
            width: '100%',
            '& > :first-of-type': {
                height: '100%',
                '& > :last-child': {
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    '& > *': {
                        width: '100%',
                    },
                },
            },
        },
        fillTwo: {
            height: '100%',
            '& > :first-of-type': {
                height: '100%',
                '& > :last-child': {
                    height: '100%',
                },
            },
        },
        headerItems: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: theme.spacing(0, 2),
        },
        noTitle: {
            display: 'flex',
            float: 'right',
            marginRight: theme.spacing(1)
        }
    }

    const handleAction = () => {
        groupboxAction();
    }

    const stateReload = () => {
        if (showStatReleoad) {
            handleStateReload();
        }
    }

    return (
        <Card variant="outlined" className={clsx(className, { [classes.gutterBottom]: gutter, [classes.fill]: fill, [classes.fillTwo]: fillTwo })} {...other}>
            <CardContent sx={styles.main} className={classes.main}>
                <React.Fragment>
                    <div className={(title ?? false) ? classes.headerItems : classes.noTitle}>
                        {
                            title &&
                            <Typography className={classes.header} variant="h6" color="textSecondary">
                                {title}
                            </Typography>
                        }
                        {actionTitle && <Button onClick={handleAction} endIcon={<ArrowForward />} color="secondary">{actionTitle}</Button>}
                        {(showStatReleoad || headerActions || headerSubComponents) &&
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {
                                    (showStatReleoad) &&
                                    <IconButton aria-label="refresh" size="small" onClick={stateReload}>
                                        <Refresh />
                                    </IconButton>
                                }
                                {headerSubComponents}
                                {headerActions}
                            </div>
                        }
                    </div>
                    {(showDivider && !isCollapsed) && <Divider light />}
                </React.Fragment>
                {!isCollapsed && (
                    <div className={clsx(NoPadding ? classes.noPadding : (smallBodyPadding ? classes.bodyTwo : classes.body), { [classes.flexBody]: flex }, { [classes.justifyCenter]: center }, { [classes.slim]: slim }, { [classes.flexNoWrap]: flexNoWrap })}>
                        {children}
                    </div>
                )}
            </CardContent>
        </Card >
    );
}