import {
    ClassicEditor,
    Essentials,
    CKFinderUploadAdapter,
    Autoformat,
    Bold,
    Italic,
    BlockQuote,
    CKBox,
    CKFinder,
    EasyImage,
    Heading,
    Image,
    ImageCaption,
    ImageStyle,
    ImageToolbar,
    ImageUpload,
    PictureEditing,
    Indent,
    Link,
    List,
    MediaEmbed,
    Paragraph,
    PasteFromOffice,
    Table,
    TableToolbar,
    TextTransformation,
    CloudServices,
    Underline,
    Strikethrough,
    FontBackgroundColor,
    FindAndReplace,
    FontFamily,
    FontSize,
    FontColor,
    HorizontalLine,
    Superscript,
    Subscript,
    RemoveFormat,
    SelectAll,
    Autosave,
    Alignment,
    TableColumnResize,
    TableProperties,
    SourceEditing,
    ShowBlocks,
    CodeBlock,
    SpecialCharacters,
    Highlight
} from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';
import AiMenuPlugin from '../../features/conversation/components/CustomAIButton';
import MessageTemplatePlugin from '../../features/conversation/components/CustomMessageTemplateButton';
import AutoReplyPlugin from '../../features/conversation/components/CustomAutoRplyButton';

class Editor extends ClassicEditor { }
Editor.builtinPlugins = [
    Essentials,
    CKFinderUploadAdapter,
    Bold,
    Italic,
    BlockQuote,
    CKBox,
    CKFinder,
    CloudServices,
    EasyImage,
    Heading,
    Image,
    ImageCaption,
    ImageStyle,
    ImageToolbar,
    ImageUpload,
    Indent,
    Link,
    List,
    MediaEmbed,
    Paragraph,
    PasteFromOffice,
    PictureEditing,
    Table,
    TableToolbar,
    TableColumnResize,
    TextTransformation,
    TableProperties,
    Underline,
    Strikethrough,
    FontBackgroundColor,
    FindAndReplace,
    FontFamily,
    FontSize,
    FontColor,
    Highlight,
    HorizontalLine,
    Superscript,
    Subscript,
    Autoformat,
    RemoveFormat,
    SelectAll,
    Autosave,
    Alignment,
    List,
    SourceEditing,
    ShowBlocks,
    CodeBlock,
    SpecialCharacters,
    MessageTemplatePlugin,
    AutoReplyPlugin,
    AiMenuPlugin,
];

Editor.defaultConfig = {
    toolbar: {
        items: [
            'heading',
            '|',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'superscript',
            'subscript',
            'removeFormat',
            '|',
            'highlight',
            'fontFamily',
            'fontSize',
            'fontColor',
            'fontBackgroundColor',
            '|',
            'link',
            'blockquote',
            'horizontalLine',
            'insertTable',
            '|',
            'List',
            'indent',
            'outdent',
            'alignment',
            '|',
            'findAndReplace',
            'selectAll',
            'codeBlock',
            '|',
            'undo',
            'redo',
            'messageTemplate',
            'autoReply',
            'aiMenu',
        ],
        shouldNotGroupWhenFull: true,
    },
    table: {
        contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells'
        ]
    },
    typing: {
        transformations: {
            remove: ['quotes', 'ellipsis', 'quotesPrimary', 'quotesSecondary', 'smartQuotes', 'dashes', 'hyphens', 'fractions']
        }
    },
    language: 'en',
    isReadOnly: true
};

export default Editor;