import React, { useEffect, useRef } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from '../../../components/CKEditor5/CustomCKEditor';
import { useTheme } from '@mui/styles';
import { ConversationStatus, ConversationType, EmailOperation } from '../../../helpers/Constant';
import { ConversationService } from '../../../services/ConversationService';
import { useDispatch, useSelector } from 'react-redux';
import { setMessageAction, updateContent, updateThreadData, updateFiles, setParticipants, resetParticipants } from '../ConversationSlice';
import { HandleServerResponse } from '../../../helpers/Common';
import MessageHeader from '../components/MessageHeader';
import MessageTemplate from '../components/dialogs/MessageTemplate';
import AutoReply from '../ai/AutoReply';
import AiDialog from '../ai/AiDialog';
import MessageTemplatePlugin from '../components/CustomMessageTemplateButton';
import AutoReplyPlugin from '../components/CustomAutoRplyButton';
import AiMenuPlugin from '../components/CustomAIButton';
import { styled } from '@mui/material/styles';

const DarkTheme = React.lazy(() => import('../../settings/list/ckeditorTheme/DarkTheme'));

const Root = styled('div')(({ theme }) => ({
    width: '100%',
    border: '1px solid #0000001f',
    borderRadius: '4px'
}));

const EditorContainer = styled('div')(({ theme }) => ({
    width: '100%',
    '& .ck-editor__editable_inline': {
        minHeight: '200px',
        borderTop: '1px solid #0000001f',
        border: 'none'
    },
    '& .ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content': {
        border: 'none',
        borderTop: '1px solid #0000001f !important',
        borderBottom: '1px solid #0000001f !important',
        borderRadius: '0'
    }
}));

const Message = () => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [openAutoReply, setOpenAutoReply] = React.useState(false);
    const [openMessageTemplate, setOpenMessageTemplate] = React.useState(false);
    const [selectedText, setSelectedText] = React.useState("");
    const [openAiDialog, setOpenAiDialog] = React.useState(false);
    const [selectedAiOption, setSelectedAiOption] = React.useState(null);
    const [errors, setErrors] = React.useState({ to: '', cc: '', bcc: '' });

    const { id, status, userId, lastEmail, messageAction, content, files, participants } = useSelector(state => state.conversation.current);

    const theme = useTheme();
    const editorRef = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (lastEmail && messageAction === "reply") {
            dispatch(setParticipants({
                to: [lastEmail.toRecipient],
                cc: lastEmail.ccRecipients,
                bcc: lastEmail.bccRecipients
            }));
        }
    }, [lastEmail, messageAction, dispatch]);

    const handleEditorTxt = (event, editor) => {
        const data = editor.getData();
        dispatch(updateContent(data));
    }

    const handleMessageTemplate = () => {
        setOpenMessageTemplate(true)
    };

    const handleAutoReply = () => {
        setOpenAutoReply(true)
    };

    const handleAiOption = (option) => {
        setSelectedAiOption(option);
        handleSelection();
        setOpenAiDialog(true);
    }

    const handleSelection = () => {
        if (editorRef.current) {
            const selection = editorRef.current.model.document.selection;
            const selectedRange = selection.getFirstRange();
            let selectedText = '';
            // Iterate over the selected range to get the text
            for (const item of selectedRange.getItems()) {
                if (item.is('textProxy')) {
                    selectedText += item.data;
                }
            }
            setSelectedText(selectedText); // Update selected text state
        }
    }

    const handleReplaceTextInEditor = (newText) => {
        if (editorRef.current) {
            editorRef.current.model.change((writer) => {
                const range = editorRef.current.model.document.selection.getFirstRange();
                editorRef.current.model.insertContent(writer.createText(newText), range)
            });
        }
    }

    const handleInsertTextInEditor = (newText) => {
        if (editorRef.current) {
            const selection = editorRef.current.model.document.selection;
            editorRef.current.model.change((writer) => {
                writer.insertText(newText, selection.getLastPosition());
            });
        }
    };

    const handleReply = async () => {
        if (participants.to.length === 0 && messageAction === "forward") {
            setErrors((prev) => ({
                ...prev,
                to: 'Please add atleast one email'
            }));
            return;
        }

        setIsLoading(true);
        let formData = new FormData();

        formData.append("Id", id);
        formData.append("ConversationType", ConversationType.Email);
        formData.append("ConversationOperation", messageAction === "reply" ? EmailOperation.Reply : EmailOperation.Forward);
        formData.append("EmailId", lastEmail?.id);
        formData.append("Body", content);
        participants.cc.forEach(email => formData.append('CcRecipientEmailList', email));
        participants.bcc.forEach(email => formData.append('BccRecipientEmailList', email));
        participants.to.forEach(email => formData.append('ToRecipientEmailList', email));
        files.forEach(file => {
            formData.append("Attachments", file)
        });

        let result = await ConversationService.ReplyEmail(formData);
        HandleServerResponse(result, "Email sent successfully", dispatch);

        if (result.success) {
            dispatch(updateContent(""));
            dispatch(updateFiles([]));
            dispatch(setMessageAction(null));
            dispatch(resetParticipants());
        }
        setIsLoading(false)
    }

    if (status === ConversationStatus.Completed || status === ConversationStatus.Declined || messageAction === null)
        return <></>

    return (
        <React.Fragment>
            <Root>
                <MessageHeader
                    handleReply={handleReply}
                    content={content}
                    isLoading={isLoading}
                    participants={participants}
                    errors={errors}
                    setErrors={setErrors}
                />
                <EditorContainer>
                    <React.Suspense fallback={<></>}>
                        {(theme.palette.mode === "dark") && <DarkTheme />}
                    </React.Suspense>
                    <CKEditor
                        editor={Editor}
                        data={content}
                        disabled={userId === null}
                        onChange={(event, editor) => handleEditorTxt(event, editor)}
                        onReady={(editor) => {
                            editorRef.current = editor;
                            const { plugins } = editor;

                            const aiPlugins = plugins.get(AiMenuPlugin);
                            aiPlugins.setCallback(handleAiOption);

                            const messageTemplatePlugins = plugins.get(MessageTemplatePlugin);
                            messageTemplatePlugins.setCallback(handleMessageTemplate);

                            const autoReplyPlugins = plugins.get(AutoReplyPlugin);
                            autoReplyPlugins.setCallback(handleAutoReply);
                        }}
                    />
                </EditorContainer>
            </Root>
            {
                openAutoReply &&
                <AutoReply
                    setOpenAutoReply={setOpenAutoReply}
                    handleReplaceText={handleReplaceTextInEditor}
                    handleInsertText={handleInsertTextInEditor}
                    open={openAutoReply}
                />
            }
            {
                openMessageTemplate &&
                <MessageTemplate
                    setOpenMessageTemplate={setOpenMessageTemplate}
                    handleReplaceText={handleReplaceTextInEditor}
                    handleInsertText={handleInsertTextInEditor}
                    open={openMessageTemplate}
                />
            }
            {
                openAiDialog &&
                <AiDialog
                    setOpenAiDialog={setOpenAiDialog}
                    open={openAiDialog}
                    selectedAiOption={selectedAiOption}
                    selectedText={selectedText}
                    setSelectedAiOption={setSelectedAiOption}
                    handleReplaceText={handleReplaceTextInEditor}
                    handleInsertText={handleInsertTextInEditor}
                />
            }
        </React.Fragment>
    )
}

export default Message