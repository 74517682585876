import React from "react";
import { Alert } from '@mui/material';

const FeatureLimit = () => {
    return (
        <Alert severity="error">
            Features Restricted - It appears your current user account does not have the necessary permissions to access some features or functionalities. This may be due to the specific license or permissions assigned to your account. Please contact your system administrator for further assistance and to request access to these features.
        </Alert>
    );
}

export default FeatureLimit;