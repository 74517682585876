import React from 'react';
import { Button, TextField } from '@mui/material';
import AutoCompleteExtended from '../../../../components/Inputs/AutoCompleteExtended';
import { MessageTemplateService } from '../../../../services/MessageTemplateService';
import { MessageTemplateType } from '../../../../helpers/Constant';
import { useSelector } from 'react-redux';
import CustomDialog from '../../../../components/CustomDialog';
import { formatToLongDateTime } from '../../../../helpers/Common';

const MessageTemplate = ({ open, setOpenMessageTemplate, handleReplaceText, handleInsertText }) => {
    const [messageTemplateText, setMessageTemplateText] = React.useState("");
    const [messageTemplates, setMessageTemplates] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const selectedConversation = useSelector(state => state.conversation.current)

    React.useEffect(() => {
        let active = true;
        (async () => {

            setLoading(true);

            let params = {
                messageTemplateType: MessageTemplateType.Email
            }

            var result = await MessageTemplateService.getMessageTemplatesByType(params);

            if (result.success) {
                setMessageTemplates(result.data)
            }
            setLoading(false);

        })();

        return () => { active = false; }
    }, [])

    const handleClose = () => {
        setOpenMessageTemplate(false);
    };

    const replaceKeywords = (content, values) => {
        return content.replace(/\{\$(SYSTEM\.\w+)\}/g, (match, p1) =>{
            const key = p1.replace('SYSTEM.', '');
            return values[key] || match;
        });
    }

    const handleSelectedMessageTemplate = (event) => {
        if (event.target.value !== null) {
            const keywordValues = {
                NAME: selectedConversation.threads[selectedConversation.threads.length - 1].createdBy,
                SUBJECT: selectedConversation.threads[selectedConversation.threads.length - 1].subject,
                EMAIL: selectedConversation.threads[selectedConversation.threads.length - 1].from,
                DATE: formatToLongDateTime(selectedConversation.threads[selectedConversation.threads.length - 1].date)
            }
            const replaceMessage = replaceKeywords(event.target.value.content, keywordValues);
            setMessageTemplateText(replaceMessage)
        }
    }

    const handleReplaceTextInEditor = () => {
        handleReplaceText(messageTemplateText);
        handleClose();
    };

    const handleInsertTextInEditor = () => {
        handleInsertText(messageTemplateText);
        handleClose();
    };

    return(
        <CustomDialog open={open} close={handleClose} title={"Insert Message Template"}>
            <>
                <AutoCompleteExtended
                    paddingRight={0}
                    full={true}
                    multiple={false}
                    disabled={loading}
                    onChange={handleSelectedMessageTemplate}
                    options={messageTemplates}
                    label="Message Template"
                    name="messageTemplate"    
                />
                <TextField
                    multiline
                    rows={9}
                    variant="outlined"
                    value={messageTemplateText}
                    onChange={(e) => {e.preventDefault(); setMessageTemplateText(e.target.value)}}
                    sx={{
                        width: '100%',
                        boxSizing: 'border-box',
                    }}
                />
            </>
            <>
                <Button
                    variant="outlined"
                    color="primary"
                    sx={{ marginRight: '10px' }}
                    disabled={!messageTemplateText}
                    onClick={handleInsertTextInEditor}
                >
                    Insert
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={!messageTemplateText}
                    onClick={handleReplaceTextInEditor}
                >
                    Replace
                </Button>
            </>
        </CustomDialog>
    )
};

export default MessageTemplate;
