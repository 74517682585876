import React from 'react';
import { TeamsProvider } from '@microsoft/mgt';
import { app } from "@microsoft/teams-js";
import useTheme from '@mui/styles/useTheme';
import { Toolbar, CssBaseline, Grid } from '@mui/material';
import { connect, useSelector } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import MainAppBar from './features/app/MainAppBar'
import ControlPanel from './features/controlpanel/ControlPanel';
import WallBoard from './features/wallboard/centrepal/WallBoard';
import CommunicationPanel from './features/app/CommunicationPanel';
import MessageBar from './features/app/MessageBar';
import { resetNextMove, setNextWallboard, wallboard as handleWallboard} from "./features/auth/AuthSlice"
import 'react-virtualized/styles.css'; // only needs to be imported once
import { CommunicationType, SettingExitPrompt, WallboardType } from './helpers/Constant';
import PendingRegistration from './components/PendingRegistration';
import { oldWithAuth } from './OldAuthProvider';
import withAuth from './AuthProvider';
import PowerBi from './features/wallboard/powerbi/PowerBi';

const urlParams = new URLSearchParams(window.location.search);
let upn = urlParams.get('upn');

const App = (props) => {
  const theme = useTheme();

  const styles = {
    root: {
      minHeight: '100vh',
    },
    leftPanel: {
      padding: theme.spacing(1),
      flexDirection: 'column',
      flexWrap: 'nowrap',
      width: '100%',
    },
    rightPanel: {
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
      transition: theme.transitions.create('box-shadow'),
      borderLeft: `1px solid ${theme.palette.divider}`,
      flexDirection: 'column',
      flexWrap: 'nowrap',
      width: '100%',
    }
  }

  const [showControlPanel, setShowControlPanel] = React.useState(true);
  const [wallboard, setWallboard] = React.useState(() => ({
    centrepal: false,
    powerbi:false
  }))

  const largeScreen = useMediaQuery(theme.breakpoints.up('md'), { defaultMatches: true });
  const { tabIndex, tabs } = useSelector((state) => state.auth);

  const openWallboard = (type) => {
    let current = {
      centrepal: type === WallboardType.CentrePal,
      powerbi: type === WallboardType.PowerBi,
    }

    if (SettingExitPrompt.includes(props?.subTabIndex?.settings) && props?.settingUpdated) {
      props.dispatch(setNextWallboard(current));
    }
    else {
      setWallboard(current);
      props.dispatch(handleWallboard(current));
    }
  }

  const closeWallboard = () => {
    let current = {
      centrepal: false,
      powerbi: false,
    }

    setWallboard(current);
    props.dispatch(handleWallboard(current));
  }

  React.useEffect(() => {
    const settingsIndex = tabs.findIndex(x => x.id === CommunicationType.setting);
    const conversationHistoryIndex = tabs.findIndex(x => x.id === CommunicationType.conversationHistory);
    const reportIndex = tabs.findIndex(x => x.id === CommunicationType.report);
    const callHistoryIndex = tabs.findIndex(x => x.id === CommunicationType.callHistory);

    switch(tabIndex){
      case settingsIndex:
      case conversationHistoryIndex:
      case reportIndex:
      case callHistoryIndex:
        setShowControlPanel(false);
        break;
      default:
        setShowControlPanel(true);
    }
  }, [tabIndex, tabs])

  React.useEffect(() => {
    if ((props.wallboard.centrepal && props.nextMove.wallboard.centrepal) ||
        (props.wallboard.powerbi && props.nextMove.wallboard.powerbi)) {
      setWallboard(props.wallboard);
      props.dispatch(resetNextMove());
    }
  }, [props.wallboard])


  if (props?.user?.subscription === "Free")
    return <PendingRegistration />;

  return (
    <React.Fragment>
      <CssBaseline />
      {(!wallboard.centrepal && !wallboard.powerbi) && (
        <React.Fragment>
          <MainAppBar openWallboard={openWallboard} {...props} />
          <Grid container sx={styles.root}>
            <Grid container item sx={styles.leftPanel} sm={12} md={showControlPanel ? 9 : 12}>
              <Toolbar variant="dense" />
              <CommunicationPanel {...props} largeScreen={largeScreen} />
            </Grid>
            {
              (largeScreen && showControlPanel) &&
              <Grid container item sx={styles.rightPanel} sm={12} md={3}>
                <Toolbar variant="dense" />
                <ControlPanel largeScreen={largeScreen} />
              </Grid>
            }
          </Grid>
        </React.Fragment>
      )}
      {(wallboard.centrepal) && (
        <WallBoard closeWallboard={closeWallboard} />
      )}
      {(wallboard.powerbi) && (
        <PowerBi closeWallboard={closeWallboard} />
      )}
      <MessageBar {...props} />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => state.auth;

TeamsProvider.microsoftTeamsLib = app;
var authProvider = null;

if (!TeamsProvider.isAvailable || upn !== null) {
  authProvider = connect(mapStateToProps)(withAuth(App));
} else {
  authProvider = connect(mapStateToProps)(oldWithAuth(App));
}

export default authProvider;




