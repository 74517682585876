import React, { useState } from 'react';
import { Button, Divider, Menu, MenuItem } from '@mui/material';
import { openTab, logout } from './AuthSlice';
import { CommunicationType, WallboardType, LicenseType } from "../../helpers/Constant";
import AssignQueues from './AssignQueues';
import PersonInfo from '../../components/PersonInfo';
import { checkLicense, checkUserPermission, getLocalStorage } from '../../helpers/Common';
import { Equalizer, MarkEmailRead, Queue, Settings, SettingsPhone } from '@mui/icons-material';
import About from './About';
import Issue from './Issue';
import { useTheme } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import PowerBiIcon from '../../components/customicons/PowerBiIcon';

const callHistoryPermissions = ["call.history"];
const conversationistoryPermissions = ["conversation.history"];
const settingsPermissions = ["user.read", "user.write", "queue.read", "queue.write", "role.read", "role.write", "mediagroup.read", "mediagroup.write", "queueuser.read", "queueuser.write", "settings.read", "settings.write"];
const queueUsesrPermission = ["queue.join"];
const reportPermissions = ['report.agentinteraction', 'report.agentstatus', 'report.queueinteraction', 'report.survey', 'report.ivr'];
const powerBIPermission =  ["powerbi.read"];

const LogoutComponent = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const styles = {
    button: {
      padding: 0
    },
    menuItem: {
      minHeight: 0,
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap"
    },
    menuItemText: {
      marginLeft: theme.spacing(2)
    },
    menuStyles: {
      marginTop: theme.spacing(0),
      marginRight: 50,
      '& .MuiPopover-paper': {
        top: `${theme.spacing(6)} !important`,
        minWidth: 250
      },
      '& .MuiList-padding': {
        paddingTop: 0,
      },
      '& .MuiDivider-root': {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(0),
      },
    },
    smallIcon: {
      fontSize: '1rem',
    },
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const [openAssignQueue, setOpenAssignQueue] = React.useState(false);
  const [openAbout, setOpenAbout] = React.useState(false);
  const [openIssue, setopenIssue] = React.useState(false);

  //check user permission
  const callHistoryAccess = checkUserPermission(props.user.permissions, callHistoryPermissions);
  const conversationHistoryAccess = checkUserPermission(props.user.permissions, conversationistoryPermissions);
  const settingsAccess = checkUserPermission(props.user.permissions, settingsPermissions);
  const assignQueueAccess = checkUserPermission(props.user.permissions, queueUsesrPermission);
  const reportAccess = checkUserPermission(props.user.permissions, reportPermissions);
  const powerbiAccess = checkUserPermission(props.user.permissions, powerBIPermission);

  //check user license
  const voiceLicense = checkLicense(props.user.assignedLicenses, [LicenseType.Voice, LicenseType.VoicePlusDigital]);
  const powerbiLicense = checkLicense(props.user.assignedLicenses, [LicenseType.PowerBi]);
  const digitalLicense = checkLicense(props.user.assignedLicenses, [LicenseType.VoicePlusDigital, LicenseType.AttendantConsolePlusDigital]);

  let pilot = getLocalStorage('P1') ?? false;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAssignQueueOpen = () => {
    setOpenAssignQueue(true);
    handleClose();
  };

  const handleCallHistory = () => {
    props.dispatch(openTab({ id: CommunicationType.callHistory, type: CommunicationType.callHistory, name: 'Call History', close: true, data: {} }))
    setAnchorEl(null);
  }

  const handleConversationHistory = () => {
    props.dispatch(openTab({ id: CommunicationType.conversationHistory, type: CommunicationType.conversationHistory, name: 'Conversation History', close: true, data: {} }))
    setAnchorEl(null);
  }

  const handleReports = () => {
    props.dispatch(openTab({ id: CommunicationType.report, type: CommunicationType.report, name: 'Reports', close: true, data: {} }))
    setAnchorEl(null);
  }

  const handleSettings = () => {
    props.dispatch(openTab({ id: CommunicationType.setting, type: CommunicationType.setting, name: 'Settings', close: true, data: {} }))
    setAnchorEl(null);
  }

  const handleAbout = () => {
    setOpenAbout(true);
    setAnchorEl(null);
  }

  const handleIssue = () => {
    setopenIssue(true);
    setAnchorEl(null);
  }

  const handleSignedout = () => {
    props.dispatch(logout())
    setAnchorEl(null);
  }

  const closeDialog = () => {
    setOpenAssignQueue(false);
    setopenIssue(false);
    setOpenAbout(false)
  };

  return (
    <div>
      <Button id='main-menu' size='small' style={styles.button} aria-controls="user-details" aria-haspopup="true" onClick={handleClick}>
        <PersonInfo id={props.user.userId} showImageOnly={true} />
      </Button>
      <Menu
        id="user-details"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={styles.menuStyles}
      >
        <MenuItem onClick={handleClose}>
          <PersonInfo id={props.user.userId} large={true} userLastLogin={props.user.lastLogin} />
        </MenuItem>
        <Divider />
        {(voiceLicense) &&
        <MenuItem sx={styles.menuItem} onClick={handleAssignQueueOpen}>
          <Queue sx={styles.smallIcon} />
          <div style={styles.menuItemText}>{t("Assign Queues")}</div>
        </MenuItem>
        }
        {(callHistoryAccess && voiceLicense && !props.user.mobile) &&
          <MenuItem sx={styles.menuItem} onClick={handleCallHistory}>
            <SettingsPhone sx={styles.smallIcon} />
            <div style={styles.menuItemText}>{t("Call History")}</div>
          </MenuItem>
        }
        {(pilot && conversationHistoryAccess && digitalLicense && !props.user.mobile) &&
          <MenuItem sx={styles.menuItem} onClick={handleConversationHistory}>
            <MarkEmailRead sx={styles.smallIcon} />
            <div style={styles.menuItemText}>{t("Conversation History")}</div>
          </MenuItem>
        }
        {(reportAccess && voiceLicense && !props.user.mobile) &&
          <MenuItem sx={styles.menuItem} onClick={handleReports}>
            <Equalizer sx={styles.smallIcon} />
            <div style={styles.menuItemText}>{t("Reports")}</div>
          </MenuItem>
        }
        {(powerbiAccess && powerbiLicense && !props.user.mobile) &&
          <MenuItem sx={styles.menuItem} onClick={() => props.openWallboard(WallboardType.PowerBi)}>
            <PowerBiIcon sx={styles.smallIcon}/>
            <div style={styles.menuItemText}>{t("PowerBI")}</div>
          </MenuItem>
        }
        {
          (settingsAccess && !props.user.mobile) &&
          <MenuItem id='main-menu-settings' sx={styles.menuItem} onClick={handleSettings}>
            <Settings sx={styles.smallIcon} />
            <div style={styles.menuItemText}>{t("Settings")}</div>
          </MenuItem>
        }
        <Divider />
        <MenuItem sx={styles.menuItem} onClick={() => { handleIssue() }}>
          {t("Report an Issue")}
        </MenuItem>
        <MenuItem sx={styles.menuItem} onClick={() => { handleAbout() }}>
          {t("About")}
        </MenuItem>
        <MenuItem sx={styles.menuItem} onClick={() => { handleSignedout() }}>
          {t("Sign out")}
        </MenuItem>
      </Menu>
      {openAssignQueue && <AssignQueues writePermission={assignQueueAccess} user={props.user} open={openAssignQueue} closeDialog={closeDialog} />}
      {openAbout && <About open={openAbout} closeDialog={closeDialog} />}
      {openIssue && <Issue open={openIssue} closeDialog={closeDialog} />}
    </div>
  );
}

export default LogoutComponent;