import { Box, Paper, Typography, Link as MuiLink } from '@mui/material'
import React from 'react'
import { makeStyles, useTheme } from '@mui/styles';
import Environment from '../Environment';

const useStyles = makeStyles(theme => ({
    info: {
        padding: theme.spacing(4, 6, 6, 6),
        width: 300,
    },
    version: {
        justifyContent: 'flex-end',
        textAlign: 'end',
        paddingTop: theme.spacing(5),
        marginRight: theme.spacing(1),
        fontSize: '0.60rem',
        fontWeight: 600,
        fontFamily: 'monospace',
        fontStyle: 'italic'
    },
}));

function PendingRegistration() {
    const classes = useStyles();
    const theme = useTheme();
    const styles = {
        root: {
            height: '100vh',
            backgroundColor: theme.palette.background.default,
            '& > *': {
                height: '100%',
            },
        },
        container: {
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(1)

        },
        logo: {
            width: '200px',
            height: '200px',
            margin: theme.spacing(2, 0, 2, -2),
            borderRadius: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            m: "auto",
            '& > img': {
                height: '100%',
                width: '100%',
            },
        },
    }

    return (
        <Box sx={styles.root}>
            <Box display="flex" justifyContent="center" alignItems="center">
                <Paper sx={styles.container}>
                    <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent="center">
                        <Paper variant="outlined" sx={styles.logo}>
                            <img src={window.location.origin + '/logo192.png'} alt="Logo" />
                        </Paper>
                        <Box display="flex" flexDirection='column' justifyContent="space-between">
                            <div className={classes.info}>
                                The signup process includes verifying your business details. 
                                This is a security measure that is mandatory before you can try CentrePal. 
                                You will receive an email from our Customer Success team to activate your subscription.
                            </div>
                            <div className={classes.version}>
                                <Typography variant='body2' sx={{ fontStyle: 'initial !important', mb: theme.spacing(0.5), fontSize: '0.7rem' }}>
                                    New to CentrePal?
                                    <MuiLink sx={{ ml: theme.spacing(0.5), fontSize: '0.7rem' }} onClick={() => window.open("https://www.centrepal.com/contact-centre-live-demo/?utm_source=appsource&utm_medium=web&utm_campaign=app", '_blank')} href="#" variant="body2" color="primary">
                                        Learn more
                                    </MuiLink>
                                </Typography>
                                {`Version ${Environment.config.VERSION}`}
                            </div>
                        </Box>
                    </Box>
                </Paper>
            </Box>
        </Box>
    )
}

export default PendingRegistration