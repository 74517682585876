import { Search, Visibility } from "@mui/icons-material";
import { Box, IconButton, InputAdornment, Link, OutlinedInput } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { useTranslation } from "react-i18next";
import DropDown from "../../../components/Inputs/DropDown";
import { getLocalStorage } from "../../../helpers/Common";
import { JiraService } from "../../../services/JiraService";

const useStyles = makeStyles(theme => ({
    panel: {
        display: "flex",
        flexDirection: "column",
        flexWrap: 'nowrap',
        height: '470px',
        '& > *': {
            width: '100%',
        },
    },
    dataGrid: {
        '& .MuiDataGrid-columnsContainer': {
            backgroundColor: theme.palette.background.default
        },
    },
    box: {
        float: 'right',
        marginTop: '20px',
    },
    cancelBtn: {
        width: "auto",
    },
}));

const JiraRequestList = ({ jiraConnector, addNewTab, issue, setIssue }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const searchTypes = [
        {
            id: 1,
            name: t("Summary")
        },
        {
            id: 2,
            name: t("Issue ID or Key")
        }
    ];

    const [loading, setLoading] = React.useState(false);
    const [grid, setGrid] = React.useState(() => ({
        hide: { id: false },
    }))

    const handleSearchValue = (event) => {
        event.preventDefault();
        setIssue((prev) => (
            {
                ...prev,
                [event.target.name]: event.target.value
            }
        ));
    }

    const handleSearchType = (event) => {
        event.preventDefault();
        setIssue((prev) => (
            {
                ...prev,
                [event.target.name]: event.target.value
            }
        ));
        if (issue.search !== '') {
            handleSearch(event.target.value);
        }
    }

    const handleSearch = (type = null) => {
        (async () => {
            setLoading(true);

            let params = {
                ConnectorId: jiraConnector.connectorId,
                SearchType: type === null ? issue.type : type,
                SearchValue: issue.search,
            };

            if (jiraConnector.authType === 1) {
                const jiraCredentials = getLocalStorage('jira-auth')
                params['AccessToken'] = jiraCredentials.accessToken;
            }

            let result = await JiraService.SearchIssues(params);

            if (result.success) {
                setIssue((prev) => (
                    {
                        ...prev,
                        'list': result.data
                    }
                ));
            }
            setLoading(false);
        })();
    }

    const onKeyDown = (e) => {
        if (e.key === "Enter") {
            handleSearch();
        }
    }

    const actions = (params) => {
        return (
            <React.Fragment>
                <IconButton color="default" fontSize="small" aria-label="more options" title="View ticket" size="small" onClick={(e) => addNewTab(params.row)}>
                    <Visibility fontSize="small" />
                </IconButton>
            </React.Fragment>
        )
    }

    const columns = [
        { field: 'id', headerName: 'Issue Id', type: 'hex', flex: 1, sortable: false },
        {
            flex: 1,
            field: 'issueKey',
            headerName: t('Issue Key'),
            sortable: false,
            renderCell: (params) => <div><Link href={params.row.webUrl} target="_blank" rel="noopener noreferrer">{params.row.issueKey}</Link></div>
        },
        { field: 'summary', headerName: t('Summary'), flex: 2, sortable: false },
        { field: 'status', headerName: t('Status'), flex: 1, sortable: false },
        { field: 'createdDate', headerName: t('Created Date'), flex: 1, sortable: false },
        {
            field: 'action',
            headerName: t('Actions'),
            sortable: false,
            width: 100,
            align: 'center',
            renderCell: actions
        },
    ]

    return (
        <React.Fragment>
            <Box className={classes.panel}>
                <Box display="flex" sx={{ float: 'right', width: '100%' }} justifyContent="right" alignItems="right" py={1}>
                    <DropDown
                        style={{
                            marginRight: "20px",
                            marginTop: 0,
                            marginBottom: 0
                        }}
                        size="extra-small"
                        label={t("Search Type")}
                        value={issue.type}
                        onChange={(e) => handleSearchType(e)}
                        name={"type"}
                        values={searchTypes}
                    />
                    <OutlinedInput
                        placeholder={t("Search")}
                        name="search"
                        margin="dense"
                        value={issue.search}
                        style={{ paddingRight: 0 }}
                        onChange={handleSearchValue}
                        onKeyDown={onKeyDown}
                        endAdornment={
                            <InputAdornment position="start">
                                <IconButton disabled={issue.search === ''} onClick={() => handleSearch()} size="large">
                                    <Search />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </Box>
                <DataGrid
                    rows={issue.list}
                    columns={columns}
                    loading={loading}
                    hideFooterSelectedRowCount={true}
                    className={classes.dataGrid}
                    density="compact"
                    autoHeight
                    onColumnVisibilityModelChange={(newModel) => {
                        setGrid(prev => {
                            let info = { ...prev };
                            info.hide = newModel;
                            return info;
                        })
                    }}
                    columnVisibilityModel={grid.hide}
                />
            </Box>
        </React.Fragment>
    )
}

export default JiraRequestList;